import classNames from 'classnames';
import { CSSProperties } from 'react';
import { getImageClassesL4, getImageWrapperStyles } from '../../Utils';
import { DEFAULT_IMAGE, MEDIA_TYPES } from '../../Constants';

export const ImageCardL4 = ({
  testimonialIndex,
  activeTestimonialIdx,
  totalItems,
  imageSizeActive,
  imageSizeInactive,
  viewAbleImageLimit,
  arrIndex,
  authorImageRef,
  onImageClick,
  mediaUrl,
  mediaType,
  imageShape,
  isSmartShape,
  containerWidthSmartShape,
}) => {
  const isActive = testimonialIndex === activeTestimonialIdx % totalItems;
  const imageSize = isActive ? imageSizeActive : imageSizeInactive;
  const minClickAbleIndex = (viewAbleImageLimit - 1) / 2;
  const maxClickAbleIndex = minClickAbleIndex + viewAbleImageLimit - 1;

  const imageStyle: CSSProperties = !isSmartShape
    ? {
        width: imageSize,
        height: imageSize,
      }
    : {};

  return (
    <div
      ref={(node) => {
        if (arrIndex < minClickAbleIndex || arrIndex > maxClickAbleIndex) {
          return null;
        }
        authorImageRef.current[testimonialIndex] = node;
      }}
      key={arrIndex}
      onClick={() => onImageClick(testimonialIndex)}
      className={classNames(
        getImageClassesL4(isActive),
        'tw-flex tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-pb-[8px]'
      )}
    >
      <div
        className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden"
        style={{
          ...getImageWrapperStyles(imageShape),
          ...(isSmartShape
            ? {
                width: containerWidthSmartShape,
                height: 0.6 * containerWidthSmartShape,
              }
            : {}),
        }}
      >
        {mediaType !== MEDIA_TYPES.VIDEO ? (
          <img
            src={mediaUrl || DEFAULT_IMAGE}
            className="removeGlobalOverride tw-max-h-full tw-max-w-full tw-object-cover"
            style={imageStyle}
          />
        ) : (
          <video
            src={mediaUrl}
            autoPlay
            loop
            muted
            style={imageStyle}
            className="removeGlobalOverride tw-max-h-full tw-max-w-full tw-object-cover"
          />
        )}
      </div>
    </div>
  );
};
