import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { useAutoScrollOrRotation } from '../../../../hooks/useAutoScrollOrRotation';
import {
  getActiveTestimonialIndex,
  getAuthImageListMaxWidth,
  getImageLimit,
  getImageList,
  scrollImageToConatinerCenter,
} from '../../Utils';
import { ImageCardL4 } from './ImageCardL4';
import { IMAGE_SIZE_L4, TESTIMONIAL_IMAGE_SHAPES } from '../../Constants';
import { GlobalLoader } from 'src/components/WidgetMaker/WidgetDnD/globalLoader';

const SMART_FIT_MAX_WIDTH_MOBILE = 60;
const SMART_FIT_MAX_WIDTH_DESKTOP = 100;
const IMAGE_GAP_MOBILE = 20;
const IMAGE_GAP_DESKTOP = 36;

const AuthorImageList = ({
  listPresentationConfig,
  carouselIndicatorConfigs,
  isMobile,
  carouselScrollTo,
  totalItems,
  testimonialsData,
  imageShape,
}) => {
  const transitionRef = useRef(null);
  const [showLoader, setShowLoader] = useState(true);

  const scrollerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<Record<number, HTMLDivElement>>({});

  const activeTestimonialIdx = getActiveTestimonialIndex({
    carouselIndicatorConfigs,
    totalItems,
  });

  const isSmartShape = imageShape === TESTIMONIAL_IMAGE_SHAPES.SMART;
  const containerWidthSmartShape = !isMobile
    ? SMART_FIT_MAX_WIDTH_DESKTOP
    : SMART_FIT_MAX_WIDTH_MOBILE;

  const { imageSizeActive, imageSizeInactive } = IMAGE_SIZE_L4;

  const imageGap = isMobile ? IMAGE_GAP_MOBILE : IMAGE_GAP_DESKTOP;

  const { isAutoRotateEnabled } = useAutoScrollOrRotation({ listPresentationConfig });

  /**
   * viewAbleImageLimit is nomber of images which would be visible in the conatiner.
   * it also help us to determine the witdh of conatiner containing image list.
   */
  const viewAbleImageLimit = getImageLimit(isMobile);

  const showRotation = totalItems > viewAbleImageLimit && scrollerRef.current;

  // max width of image list container.
  const maxWidth = useMemo(
    () =>
      getAuthImageListMaxWidth({
        viewAbleImageLimit,
        isSmartShape,
        containerWidthSmartShape,
        imageSizeActive,
        imageSizeInactive,
        imageGap,
      }),
    [viewAbleImageLimit, imageShape]
  );

  const [imageList, setImageList] = useState(getNewImageList());

  /**
   * reset Image list on change in visible testimonialList or the selected view port.
   */
  useEffect(() => {
    setImageList(getNewImageList());
  }, [testimonialsData?.length, isMobile, imageShape]);

  // prevent mouse scroll in image list conatiner.
  useDisableScroll({
    disableScroll: isAutoRotateEnabled,
    scrollRef: scrollerRef.current,
  });

  /**
   * 1. Scroll the active image to center.
   * 2. update the image list after rotation.
   */
  useEffect(() => {
    let timeoutId;
    if (showRotation) {
      transitionRef.current = true;

      scrollImageToCenter();

      timeoutId = setTimeout(() => {
        setImageList(getNewImageList());
      }, 1500);
    }
    return () => clearTimeout(timeoutId);
  }, [activeTestimonialIdx, showRotation]);

  /**
   * if images are overflowing container,
   * Instanlty center the active image in the updated image list to prevent layout shift
   */
  useEffect(() => {
    if (showRotation) {
      scrollImageToCenter(true);
      transitionRef.current = false;
    }
  }, [imageList, showRotation]);

  function scrollImageToCenter(instantScroll = false) {
    const element = imageRef.current[activeTestimonialIdx];
    scrollImageToConatinerCenter({
      element,
      scrollerRef,
      instantScroll,
      showLoader,
      setShowLoader,
    });
  }

  function getNewImageList() {
    return getImageList({
      showRotation,
      totalItems,
      activeTestimonialIdx,
      viewAbleImageLimit,
      testimonialsData,
    });
  }

  function handleImageClick(index) {
    // prevent image click in between transiition when there is overflow(rotation applicable).
    if (!showRotation || !transitionRef.current) {
      showRotation && (transitionRef.current = true);

      /**
       * this would instantly scroll the slide at given index, active testimonial index would be updated by list presentation component.
       * eventually active testimonial image would be scrolled to the center,later on image list would be updated.
       */
      carouselScrollTo?.(index, true);
    }
  }

  return (
    <div
      className="relative tw-mx-auto tw-mt-[36px] tw-flex tw-max-w-full tw-items-center tw-justify-center"
      style={{
        width: maxWidth,
      }}
    >
      {showRotation && showLoader && (
        <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <GlobalLoader type="loader1" />
        </div>
      )}
      <div
        ref={scrollerRef}
        className={classNames(
          'no-scrollbar tw-flex tw-w-full tw-flex-shrink-0 tw-overflow-auto ',
          !showRotation ? 'tw-justify-center' : '',
          showRotation && showLoader ? 'tw-invisible' : ''
        )}
        style={{
          gap: imageGap,
        }}
      >
        {imageList.map(({ mediaUrl, mediaType, index }, arrIndex) => (
          <ImageCardL4
            key={index}
            testimonialIndex={index}
            activeTestimonialIdx={activeTestimonialIdx}
            totalItems={totalItems}
            imageSizeActive={imageSizeActive}
            imageSizeInactive={imageSizeInactive}
            viewAbleImageLimit={viewAbleImageLimit}
            arrIndex={arrIndex}
            authorImageRef={imageRef}
            onImageClick={handleImageClick}
            mediaUrl={mediaUrl}
            mediaType={mediaType}
            imageShape={imageShape}
            isSmartShape={isSmartShape}
            containerWidthSmartShape={containerWidthSmartShape}
          />
        ))}
      </div>
    </div>
  );
};

export default AuthorImageList;
